import { useEffect, useState } from "react";
import { IStatisticalChart } from '../../statistical/models/IStatisticalChart';
import "../ThongKeSCYK.scss";
import { StatisticalChart } from './StatisticalChart';

interface IProps {
  khoaPhong: any;
}

const BaoCaoTheoKhoaPhong = (props: IProps) => {
  const { khoaPhong } = props;
  const [dataChart, setDataChart] = useState<IStatisticalChart>();
  useEffect(() => {
    setDataChart({
      title: "Biểu đồ thống kê sự cố theo khoa phòng",
      options: {
        tooltip: {
          trigger: 'axis',
          formatter: function (params: any) {
            const item = params[0]; // Lấy dữ liệu đầu tiên trong params
            return `<span className="text-primary">${item.name} - ${khoaPhong[item.dataIndex]?.tenKhoaPhong} : ${khoaPhong[item.dataIndex]?.soLuongSuCo}</span>`; // Hiển thị tên khoa
          },
          textStyle: {
            fontFamily: 'Arial',  // Đổi font chữ cho tooltip
            fontSize: 12
          }
        },
        xAxis: {
          type: 'category',
          data: khoaPhong?.map((item: any) => item?.maKhoaPhong)
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [{
          type: 'slider',  // Thanh cuộn ngang
          xAxisIndex: 0    // Áp dụng cho trục x
        }],
      
        series: [{
          name: 'Tỉ lệ',
          type: 'bar',
          
          data:  khoaPhong?.map((item: any) => item?.soLuongSuCo) // ví dụ về dữ liệu
        }]
      }
    });
  }, [khoaPhong]);

  return (
    <StatisticalChart options={dataChart?.options} title={dataChart?.title} />
  );
};

export default BaoCaoTheoKhoaPhong;